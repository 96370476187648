/* Размеры и позиционирование спиннера */
.loaderSpinner {
    display: inline-block;
    position: relative;
    width: 66px;
    height: 66px
}

/* Анимация иcчезновения и появления элемента спиннера */
.loaderSpinner span {
    display: block;
    transform-origin: 33px 33px;
    animation: loaderSpinner 1s linear infinite
}
@keyframes loaderSpinner {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

/* "Палочки" элемента спиннера */
.loaderSpinner span:after {
    content: " ";
    display: block;
    position: absolute;
    top: 0px;
    left: 30px;
    width: 6px;
    height: 18px;
    border-radius: 3px;
    background: #d30d15
}

/* Поворот и задержка анимации отдельных элементов спиннера */
.loaderSpinner span:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1s
}
.loaderSpinner span:nth-child(2) {
    transform: rotate(45deg);
    animation-delay: -0.875s
}
.loaderSpinner span:nth-child(3) {
    transform: rotate(90deg);
    animation-delay: -0.75s
}
.loaderSpinner span:nth-child(4) {
    transform: rotate(135deg);
    animation-delay: -0.625s
}
.loaderSpinner span:nth-child(5) {
    transform: rotate(180deg);
    animation-delay: -0.5s
}
.loaderSpinner span:nth-child(6) {
    transform: rotate(225deg);
    animation-delay: -0.375s
}
.loaderSpinner span:nth-child(7) {
    transform: rotate(270deg);
    animation-delay: -0.25s
}
.loaderSpinner span:nth-child(8) {
    transform: rotate(315deg);
    animation-delay: -0.125s
}

/* Фон прелоадера */
.preLoader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    background: #fff;
    opacity: 1;
    transition: .3s ease-in
}

/* Позиция спиннера в прелоадере */
.preLoader .loaderSpinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

/*  Стилизация контейнера для данных */
.container {
    max-width: 1210px;
    padding: 0 3rem;
    margin: 0 auto
}

/* Flexbox-строка */
.flexRow {
    display: flex;
    flex-direction: row;
}

/* Контейнер, занимающий весь экран */
.fullScreen {
    position: relative
}

/* Отключение отступа в последнем абзаце контейнера */
.fullScreen p:last-child {
    margin-bottom: 0
}

/*Разделение контейнера пополам между контентом и изображением*/
.fullScreenContent,
.fullScreenImage {
    flex: 0 0 50%;
    max-width: 50%;
    min-height: 100vh
}

/* Расположение контента во Flexbox-контейнере */
.fullScreenContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 531px;
    padding: 11.6rem 6rem 9.7rem 0;
    position: relative;
    z-index: 1
}
@media screen and (max-width: 992px) {
    .fullScreenContent {
        flex: 0 0 100%;
        max-width: 100%;
        min-height: calc(100vh - 10.9rem);
        padding-right: 0;
        padding-top: 6rem;
        padding-bottom: 6.1rem;
        justify-content: flex-start
    }
}

/* Стилизация кнопки в контейнере с контентом */
.fullScreenContent .buttonPrimary {
    display: block;
    width: 100%;
    margin-top: 3rem
}
@media screen and (max-width: 440px) {
    .fullScreenContent .buttonPrimary {
        display: block;
        width: 100%;
        margin-top: 1rem
    }
}

/* Стилизация изображения */
.fullScreenImage {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat
}
@media screen and (max-width: 992px) {
    .fullScreenImage {
        display: none
    }
}

/* Стилизация числа 404 */
.notFoundDigit {
    font-size: 9.6rem;
    line-height: 1em;
    margin-bottom: 3.5rem
}
@media screen and (max-width: 440px) {
    .notFoundDigit {
        font-size: 8.6rem;
        margin-bottom: 2rem
    }
}

/* Стилизация заголовка */
.notFoundTitle {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 1.25em;
    margin-bottom: 2.3rem
}
@media screen and (max-width: 440px) {
    .notFoundTitle {
        font-size: 2.2rem;
        margin-bottom: 1.8rem
    }
}

/* Стилизация кнопки */
.buttonPrimary {
    display: inline-block;
    padding: 1.7rem 1.5rem;
    border: none;
    border-radius: 0.8rem;
    outline: none;
    background: #d30d15;
    font-family: inherit;
    font-size: inherit;;
    color: #fff;
    font-weight: 700;
    line-height: 1.5;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: opacity .15s ease-in;
}

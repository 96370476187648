/* Отступы заголовка */
.header {
    padding: 3.2rem 0 2.5rem;
}
@media screen and (max-width: 992px) {
    .header {
        padding-top: 4.4rem
    }
}
@media screen and (max-width: 400px) {
    .header {
        padding-top: 3rem;
        padding-bottom: 2rem
    }
}

/* Стилизация прозрачного заголовка */
.header.transparent {
    background: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50
}
@media screen and (max-width: 992px) {
    .header.transparent {
        position: relative
    }
}

/* Стилизация лого в заголовке */
.header .logo {
    display: block;
    max-height: 5.9rem;
    margin-right: auto
}
@media screen and (max-width: 370px) {
    .header .logo {
        max-width: calc(100% - 13rem)
    }
}

/* Стилизация изображения в лого в заголовке */
.header .logo img {
    max-height: 100%
}

/* Стилизация контейнера для данных */
.container {
    max-width: 1210px;
    padding: 0 3rem;
    margin: 0 auto
}

/* Flexbox-строка */
.flexRow {
    display: flex;
    flex-direction: row;
}

/* Отступ после поля */
.formGroup {
    margin-bottom: 1.8rem;
    position: relative
}

/* Цвет метки поля */
.formGroup label {
    display: block;
    color: #7e7e7e
}

/* Отъезд метки наверх, когда фокус на поле */
.formGroup:focus-within label {
    top: .65rem
}
@media screen and (max-width: 992px)and (min-width: 400px) {
    .formGroup:focus-within label {
        font-size: 1.4rem;
        top: .25rem
    }
}

/* Стилизация поля ввода */
.formInput {
    display: block;
    width: 100%;
    padding: 2.5rem 3.5rem .7rem 1.6rem;
    background: #f5f5f5;
    outline: none;
    border: 1px solid #e3e3e3;
    border-radius: .8rem;
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    line-height: inherit;
    color: #000;
    transition: border .3s ease-in
}
@media screen and (max-width: 992px)and (min-width: 400px) {
    .formInput {
        padding: 1.9rem 3.5rem .75rem 1.6rem
    }
}

/* Изменение цвета границы поля ввода при наведении или фокусе */
.formInput:hover,
.formInput:focus,
.formInput:focus-visible {
    border-color: #7e7e7e
}

/* Отъезд метки наверх, когда в поле нет placeholder`а (есть какие-то данные) */
.formInput:not(:-ms-input-placeholder)+.formLabel {
    top: .65rem
}
.formInput:not(:placeholder-shown)+.formLabel {
    top: .65rem
}
@media screen and (max-width: 992px)and (min-width: 400px) {
    .formInput:not(:-ms-input-placeholder)+.formLabel {
        font-size: 1.4rem;
        top: .25rem
    }

    .formInput:not(:placeholder-shown)+.formLabel {
        font-size: 1.4rem;
        top: .25rem
    }
}

/* Появление крестика, когда в поле нет placeholder`а */
.formInput:not(:-ms-input-placeholder)+.formLabel+.formClear {
    opacity: 1;
    z-index: 0
}
.formInput:not(:placeholder-shown)+.formLabel+.formClear {
    opacity: 1;
    z-index: 0
}

/* Прозрачный placeholder */
.formInput:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0);
    -ms-transition: color .15s ease-in;
    transition: color .15s ease-in
}
.formInput::placeholder {
    color: rgba(0, 0, 0, 0);
    transition: color .15s ease-in
}

/* Придание placeholder`у цвета при фокусе */
.formInput:focus:-ms-input-placeholder {
    color: #7e7e7e
}
.formInput:focus::placeholder {
    color: #7e7e7e
}

/* Стилизация окна ввода при ошибке */
.formInput.error {
    border-color: #d30d15;
    background: rgba(211, 13, 21, .1)
}

/* Стилизация контейнера для "крестика" в поле ввода */
.formClear {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    position: absolute;
    top: 2rem;
    right: 2.1rem;
    opacity: 0;
    z-index: 15;
    transition: opacity .3s ease-in
}
/* Стилизация svg для "крестика" в поле ввода */
.formClear svg {
    width: 9px;
    height: 9px;
}
.formClear svg path {
    stroke: #7e7e7e;
    transition: stroke .15s ease-in
}

/* Стилизация метки поля ввода */
.formLabel {
    display: block;
    max-width: calc(100% - 5.1rem);
    position: absolute;
    top: 1.7rem;
    left: 1.6rem;
    right: 3.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: top .15s ease-in, font-size .15s ease-in;
    user-select: none;
}
@media screen and (max-width: 992px)and (min-width: 400px) {
    .formLabel {
        top: 1.5rem
    }
}

/* Стилизация текста ошибки */
.formError {
    color: #d30d15
}
/* Стилизация текста ошибки на устройствах с маленькими экранами */
@media screen and (max-width: 992px) {
    .formError {
        font-size: 1.4rem
    }
}

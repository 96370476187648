/* Стилизация оверлея */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, .4);
    opacity: 0;
    visibility: hidden;
    transition: opacity .15s ease-in
}

/* Стилизация видимого оверлея */
.overlay.show {
    opacity: 1;
    visibility: visible
}

/* Стилизация окна с сообщениями */
.notice {
    width: 37.3rem;
    max-width: 50%;
    padding: 2.3rem 3.2rem 2.6rem;
    position: fixed;
    top: 3.2rem;
    right: 50%;
    z-index: 1000;
    border-radius: .8rem;
    background: #f5f5f5;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s ease-in
}
@media screen and (max-width: 992px) {
    .notice {
        border-radius: 0;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        padding: 4.5rem 3rem 2.8rem
    }
}

/* Стилизация видимого окна с сообщениями */
.notice.show {
    visibility: visible;
    opacity: 1
}

/* Стилизация контейнера для "крестика" окна с сообщениями */
.noticeClose {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: .3rem;
    right: .3rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer
}
@media screen and (max-width: 992px) {
    .noticeClose {
        width: 1.1rem;
        height: 1.1rem;
        top: 2.8rem;
        right: 3.1rem
    }
}

/* Стилизация svg для "крестика" окна с сообщениями */
.noticeClose svg {
    width: .9rem;
    height: .9rem
}
@media screen and (max-width: 992px) {
    .noticeClose svg {
        width: 100%;
        max-height: 100%
    }
}

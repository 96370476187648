/* Отступы чекбокса */
.formCheckbox {
    padding-left: 22px;
    margin-top: 2.5rem;
    margin-bottom: 2.2rem;
    position: relative
}

/* Стилизация метки чекбокса */
.formCheckboxLabel {
    color: #7e7e7e;
    font-size: 1.2rem;
    line-height: 1.56em;
    cursor: pointer;
    user-select: none;
}
@media screen and (max-width: 992px)and (min-width: 400px) {
    .formCheckboxLabel {
        font-size: 1.6rem
    }
}

/* Стилизация элементов до и после лейбла чекбокса */
.formCheckboxLabel::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 3px;
    width: 13px;
    height: 13px;
    background: #d9d9d9
}
.formCheckboxLabel::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 1px;
    left: 0;
    background-image: url(../assets/img/check.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    opacity: 0;
    transition: opacity .15s ease-in
}

/* Стилизация элемента после лейбла чекбокса при активном чебоксе */
.formCheckbox input:checked+.formCheckboxLabel::after {
    opacity: 1
}

/* Стилизация элемента до лейбла чекбокса при ошибке */
.formCheckbox input.error+.formCheckboxLabel::before {
    background: rgba(211, 13, 21, .1)
}

/* Стилизация поля ввода чекбокса */
.formCheckbox input {
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    opacity: 0
}

/* Стилизация ошибки в чекбоксе */
.formCheckbox .formError {
    margin-left: -2.2rem
}

/* Стилизация нижнего текста */
.bottomText {
    color: #7f7f7f;
    font-style: italic;
    margin-top: 1rem;
}

/* Отступ после поля */
.formGroup {
    margin-bottom: 1.8rem;
    position: relative
}

/* Цвет метки поля */
.formGroup label {
    display: block;
    color: #7e7e7e
}

/* Отъезд метки наверх, когда фокус на поле */
.col:focus-within label {
    top: .65rem
}
@media screen and (max-width: 992px)and (min-width: 400px) {
    .col:focus-within label {
        font-size: 1.4rem;
        top: .25rem
    }
}

/* Flexbox-строка */
.formRow {
    display: flex;
    flex-direction: row;
}
@media screen and (max-width: 440px) {
    .formRow {
        flex-wrap: wrap
    }
}

/* Колонка Flexbox-строки */
.formRow .col {
    position: relative;
    padding-right: 1.6rem
}
@media screen and (max-width: 440px) {
    .formRow .col {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0
    }
}
@media screen and (max-width: 479px) {
    .formRow .col {
        max-width: 100%
    }
}

/* Вторая колонка Flexbox-строки */
.formRow .col:nth-child(2) {
    flex-shrink: 0
}

/* Стилизация метки на маленьких экранах */
@media screen and (max-width: 992px) {
    .formRow .formLabel {
        font-size: 1.4rem
    }
}

/* Стилизация поля ввода */
.formInput {
    display: block;
    width: 100%;
    padding: 2.5rem 3.5rem .7rem 1.6rem;
    background: #f5f5f5;
    outline: none;
    border: 1px solid #e3e3e3;
    border-radius: .8rem;
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    line-height: inherit;
    color: #000;
    transition: border .3s ease-in
}
@media screen and (max-width: 992px)and (min-width: 400px) {
    .formInput {
        padding: 1.9rem 3.5rem .75rem 1.6rem
    }
}

/* Изменение цвета границы поля ввода при наведении или фокусе */
.formInput:hover,
.formInput:focus,
.formInput:focus-visible {
    border-color: #7e7e7e
}

/* Отъезд метки наверх, когда в поле нет placeholder`а (есть какие-то данные) */
.formInput:not(:-ms-input-placeholder)+.formLabel {
    top: .65rem
}
.formInput:not(:placeholder-shown)+.formLabel {
    top: .65rem
}
@media screen and (max-width: 992px)and (min-width: 400px) {
    .formInput:not(:-ms-input-placeholder)+.formLabel {
        font-size: 1.4rem;
        top: .25rem
    }

    .formInput:not(:placeholder-shown)+.formLabel {
        font-size: 1.4rem;
        top: .25rem
    }
}

/* Прозрачный placeholder */
.formInput:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0);
    -ms-transition: color .15s ease-in;
    transition: color .15s ease-in
}
.formInput::placeholder {
    color: rgba(0, 0, 0, 0);
    transition: color .15s ease-in
}

/* Придание placeholder`у цвета при фокусе */
.formInput:focus:-ms-input-placeholder {
    color: #7e7e7e
}
.formInput:focus::placeholder {
    color: #7e7e7e
}

/* Стилизация окна ввода при ошибке */
.formInput.error {
    border-color: #d30d15;
    background: rgba(211, 13, 21, .1)
}

/* Стилизация метки поля ввода */
.formLabel {
    display: block;
    max-width: calc(100% - 5.1rem);
    position: absolute;
    top: 1.7rem;
    left: 1.6rem;
    right: 3.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: top .15s ease-in, font-size .15s ease-in;
    user-select: none;
}
@media screen and (max-width: 992px)and (min-width: 400px) {
    .formLabel {
        top: 1.5rem
    }
}

/* Стилизация текста ошибки */
.formError {
    color: #d30d15
}
@media screen and (max-width: 992px) {
    .formError {
        font-size: 1.4rem
    }
}

/* Стилизация запроса на повторный звонок */
.recall,
.recallDisabled {
    margin-top: 2rem
}
@media screen and (max-width: 992px) {
    .recall,
    .recallDisabled {
        font-size: 1.4rem
    }
}
@media screen and (max-width: 440px) {
    .recall,
    .recallDisabled, {
        margin-top: 1rem
    }
}

/* Стилизация запроса на повторный звонок, когда таймер не истёк */
.recallDisabled {
    color: grey;
}

/* Стилизация запроса на повторный звонок, когда таймер истёк */
.recall {
    cursor: pointer;
}

/* Стилизация запроса на повторный звонок, когда таймер истёк при наведении курсора */
.recall:hover {
    text-decoration: underline
}

/* Стилизация подписи под окном ввода */
.formGroupDescription {
    font-size: 1.1rem;
    line-height: 2em;
    color: #7e7e7e
}
@media screen and (max-width: 992px) {
    .formGroupDescription {
        font-size: 1.4rem;
        line-height: 1.6em;
        margin-top: 1.6rem
    }
}

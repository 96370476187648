html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

body {
    line-height: 1
}

ol,
ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

body {
    font-family: "Inter", sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000
}

@media screen and (max-width: 992px) {
    body {
        font-size: 1.8rem
    }
}

@media screen and (max-width: 400px) {
    body {
        font-size: 1.4rem
    }
}

p {
    font-size: inherit;
    line-height: 1.6em;
    margin-bottom: 1.6em
}

a {
    color: #d30d15;
    text-decoration: none
}

a:hover {
    text-decoration: underline
}

ul {
    padding-left: 1.5em;
    margin-bottom: 1em
}

ul li {
    list-style-type: disc
}

h1 {
    font-size: 3.2rem;
    font-weight: 700;
    margin-bottom: 3.1rem
}

@media screen and (max-width: 768px) {
    h1 {
        font-size: 2.4rem;
        margin-bottom: 2rem
    }
}

@media screen and (max-width: 370px) {
    h1 {
        font-size: 2rem
    }
}

html {
    font-size: 10px
}

*,
*::after,
*::before {
    box-sizing: border-box
}

.container {
    max-width: 1210px;
    padding: 0 3rem;
    margin: 0 auto
}

@media screen and (max-width: 1240px) {
    .container {
        max-width: 992px
    }
}

img {
    max-width: 100%
}

b {
    font-weight: 700
}

iframe {
    margin: 3rem 0
}

button {
    display: inline-block;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Чтобы хромиум не изменял фон при автодополнении */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #F2F2F2 inset !important;
}
